import Image from "next/image";

import { FormValues } from "@/interfaces/searchBlock";
import { SearchBlockContainer } from "@/components/common/searchBlock/SearchBlockContainer";
import { Section } from "@/components/common/section/Section";
import { defaultImageQuality } from "@/const/imageQuality";
import styles from "./HeroBlock.module.scss";
import { PromoBanner, PromoBanners } from "./promoBanners/PromoBanners";

type TopImage = {
  url: string;
  alternativeString: string;
};

export type HeroBlockProps = {
  title: string | React.ReactNode;
  subtitle?: string | React.ReactNode;
  promoBanners?: PromoBanner[];
  topImage?: TopImage;
  searchBlockInitialState?: FormValues;
  hideSearchBlock?: boolean;
};

export const HeroBlock = ({
  title,
  subtitle,
  promoBanners,
  topImage,
  searchBlockInitialState,
  hideSearchBlock,
}: HeroBlockProps) => {
  return (
    <Section
      containerStyle={
        hideSearchBlock
          ? styles.heroBlockWithoutSearchBlock
          : styles.heroBlockWithSearchBlock
      }
    >
      <div className={styles.heroBlock}>
        {topImage && (
          <div className={styles.imageWrapper}>
            <Image
              src={topImage.url}
              objectFit="contain"
              objectPosition="top"
              alt={topImage.alternativeString}
              layout="fill"
              quality={defaultImageQuality}
            />
          </div>
        )}

        <div className={styles.mobilePaddingContainer}>
          <h1>{title}</h1>
          <div className={styles.heroBlockUnderline}></div>
          {subtitle !== undefined && <h3>{subtitle}</h3>}
        </div>

        {promoBanners && promoBanners.length > 0 ? (
          <PromoBanners promoBanners={promoBanners} />
        ) : null}

        {!hideSearchBlock && (
          <div className={styles.searchBlockPositioner}>
            <SearchBlockContainer
              initialState={searchBlockInitialState}
              lazyLoadDatePickers
              setIsOneWayTrip={undefined}
            />
          </div>
        )}
      </div>
    </Section>
  );
};

export default HeroBlock;
